<template>
  <div class="w-100">
    <b-overlay :show="loading" no-wrap spinner-variant="primary"></b-overlay>
    <b-card>
      <b-row v-if="activeUser !== null">
        <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
          <div class="d-flex justify-content-start">
            <b-avatar src="@/assets/images/logo/profile.png" style="background: #fff;" size="104px" rounded />
            <!-- <b-avatar :src="client.avatar" style="background: #fff;" size="104px" rounded /> -->
            <div class="d-flex flex-column ml-1 justify-content-center">
              <div class="mb-1">
                <h4 >
                  {{activeUser.full_name}}
                </h4>
              </div>
            </div>
          </div>
        </b-col>

        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
            <tr v-for="(num, index) in activeUser.contacts"
              :key="index"
            >
              <th class="pb-50">
                <b-icon icon="telephone"></b-icon>
                <span class="font-weight-bold ml-1">{{$t('data.mobile_number')}}</span>
              </th>
              <td class="pb-50">
                {{ num.number }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <b-icon icon="person-fill"></b-icon>
                <span class="font-weight-bold ml-1">{{$t('message.Role')}}</span>
              </th>
              <td class="pb-50 text-capitalize">
                <span
                  v-for="(role, index) in activeUser.roles"
                  :key="index"
                >
                  {{role.name}}
                  <span v-if="index !== (activeUser.roles.length - 1)" class="mx-1">/</span>
                </span>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <b-icon icon="building"></b-icon>
                <span class="font-weight-bold ml-1">{{$t('message.Branch')}}</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{activeUser.branch.name}}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
<!--      <b-button @click="printing">print</b-button>-->
    </b-card>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'ProfileHeader',
    computed: {
      ...mapGetters('auth', ['activeUser']),
      checkRoleUser() {
        if(this.activeUser !== null) {
          return this.activeUser.roles.some(item => item.name == 'SuperAdmin')
        }
        return false
      },
    },
    data() {
      return {
        msg: {
          "operation-attributes-tag": {
            //use these to view completed jobs...
//	"limit": 10,
            "which-jobs": "completed",

            "requested-attributes": [
              "job-id",
              "job-uri",
              "job-state",
              "job-state-reasons",
              "job-name",
              "job-originating-user-name",
              "job-media-sheets-completed"
            ]
          }
        },
        printer: null,
        loading: true,
      }
    },
    mounted() {
      var ipp = require('ipp');
      var id = 0x0123;//made up reqid
      // var PDFDocument = require('pdfkit');
      // var serialize = require('./lib/serializer');

      // console.log(serialize(this.msg))
      // var ippReq = require('./lib/request')
      // ippReq("ipps://192.168.123.100:9100/ipp/print",serialize(this.msg), function(err, res) {
      //   console.log(err,res)
      // })
      setTimeout(() => {
        this.loading = false;
      }, 300)


    },
    methods: {
      printing(){
        var ipp = require('ipp');
        var id = 0x0123;//made up reqid
        this.printer = ipp.Printer("ipp://192.168.123.100:9100");

        var msg = {
          "operation-attributes-tag": {
            "requesting-user-name": "Bumblebee",
            "job-name": "whatever.pdf",
            "document-format": "application/pdf"
          },
          "job-attributes-tag":{
            "media-col": {
              "media-source": "tray-2"
            }
          }

        };
        this.printer.execute("Get-Jobs", msg, function(err, res){
          if (err) return console.log(err);
          console.log(res['job-attributes-tag']);
        });
      }
    }
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-profile.scss';
</style>
